import React from "react"
import Layout from "../components/layout"
import Actu from "../components/Actus"

const ActusPage = props => {
  return (
    <Layout title="Nos actualités" {...props} description="Toutes l'actualités seras afficher ici">
      <Actu />
    </Layout>
  )
}

export default ActusPage
